import React from 'react'
import { dateFunc } from '../_metronic/helpers/general_helper'

export default function PelatihanMendatang(props) {

    const items = props.items

    const pelatihanMendatang = items.map(item => (
        <div key={item.id_diklat} className="card mb-4 shadow-sm pelatihan-mendatang" id="pelatihan_satu" style={{'height':'180px'}}>
            <div className="card-header">
                <div className="card-title fs-6">{item.nama}</div>
            </div>
            <div className="card-body py-2">
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Tanggal Pelaksanaan</div>
                    <div className="col">{dateFunc(item.tanggal_mulai)} - {dateFunc(item.tanggal_selesai)}</div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Materi</div>
                    <div className="col">{item.jumlah_materi} MD / {item.jumlah_jpl} JPL</div>
                </div>
            </div>
        </div>
    ))

    return (
        <div className="card mb-4 overflow-hidden" style={{'height': '71vh'}}>
            <div className="card-header bg-white" style={{'zIndex': '2'}}>
                <div className="card-title" >{props.jenis}</div>
            </div>
            <div className="card-body py-2">
                <div className="d-flex flex-column marquee" id="pelatihan_wrapper" data-duplicated='true' data-direction='left'>
                    {pelatihanMendatang}
                </div>
            </div>
        </div>
    )
}