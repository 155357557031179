import React from 'react'

export default function Kontak() {
    return (
        <div className="card card--kontak" style={{'height': '10vh'}}>
            <div className="card-body fs-5 d-flex align-items-center">
                <span>Nomor Kontak : </span>
                <span>(021) 5712619</span>
            </div>
        </div>
    )
}