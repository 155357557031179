import $ from 'jquery'

$(document).ready(function () {
    runPelatihanMendatang();
    // runInfoPelatihan();
    runInformasiPelatihan();
    runDetailPelatihan();
    runJenisPelatihan();
    runSummaryPelatihan();
    runCarouselPelatihan();
    // runScroll($("#pelatihan_wrapper"), $("#pelatihan_wrapper .card"));
    // runScroll($("#info_pelatihan_wrapper"), $("#info_pelatihan_wrapper .content-slide"));
});

// function runScroll(wrapper, content) {
//     txt = wrapper;
//     length = content.length;
//     height_ul = parseInt(txt.height());
//     height_li = height_ul / length;
//     console.log(length);
//     if(length > 2) {
//         height_ul = height_ul - height_li;
//     }
//     delta = 0;
//     run();  
// }

// function runInfoPelatihan() {
//     pelatihan_wrapper = $("#info_pelatihan_wrapper");
//     pelatihan_length = $("#info_pelatihan_wrapper .content-slide").length;
//     height_pelatihan = parseInt(pelatihan_wrapper.height());
//     height_pelatihan_content = height_pelatihan / pelatihan_length;
//     height_pelatihan = height_pelatihan - height_pelatihan_content;
//     delta_pelatihan = 0;
//     console.log(height_pelatihan_content);
//     function scroll_li(delta_pelatihan) {
//         pelatihan_wrapper.animate({
//             top: delta_pelatihan
//         }, 3000, "linear");
//     }
//     function run() {
//         delta_pelatihan = (delta_pelatihan - height_pelatihan_content < -1 * height_pelatihan) ? height_pelatihan : delta_pelatihan - height_pelatihan_content;
//         if (delta_pelatihan <= 0) {
//             scroll_li(delta_pelatihan);
//         } else if (delta_pelatihan = height_pelatihan) {
//             pelatihan_wrapper.animate({
//                 top: delta_pelatihan
//             }, 0, "linear");
//             delta_pelatihan = 0;
//             scroll_li(delta_pelatihan);
//         }
//         setTimeout(run, 7000);
//     }function scroll_li(delta_pelatihan) {
//         pelatihan_wrapper.animate({
//             top: delta_pelatihan
//         }, 3000, "linear");
//     }
//     setTimeout(run, 7000);  
// }
function runPelatihanMendatang() {
    let pelatihan_mendatang_wrapper = $("#pelatihan_wrapper");
    let pelatihan_mendatang_length = $("#pelatihan_wrapper .card").length;
    let height_pelatihan_mendatang = parseInt(pelatihan_mendatang_wrapper.height());
    let height_pelatihan_mendatang_content = height_pelatihan_mendatang / pelatihan_mendatang_length;
    height_pelatihan_mendatang = height_pelatihan_mendatang - height_pelatihan_mendatang_content;
    let delt_pelatihan_mendatang = 0;
    function scroll_li(delt_pelatihan_mendatang) {
        pelatihan_mendatang_wrapper.animate({
            top: delt_pelatihan_mendatang
        }, 3000, "linear");
    }
    function run() {
        delt_pelatihan_mendatang = (delt_pelatihan_mendatang - height_pelatihan_mendatang_content < -1 * height_pelatihan_mendatang) ? height_pelatihan_mendatang : delt_pelatihan_mendatang - height_pelatihan_mendatang_content;
        if (delt_pelatihan_mendatang <= 0) {
            scroll_li(delt_pelatihan_mendatang);
        } else if (delt_pelatihan_mendatang === height_pelatihan_mendatang) {
            pelatihan_mendatang_wrapper.animate({
                top: delt_pelatihan_mendatang
            }, 0, "linear");
            delt_pelatihan_mendatang = 0;
            scroll_li(delt_pelatihan_mendatang);
        }
        setTimeout(run, 7000);
    }
    setTimeout(run, 7000);

}
function runInformasiPelatihan() {
    let informasi_pelatihan_wrapper = $("#informasi_pelatihan_wrapper");
    let informasi_pelatihan_length = $("#informasi_pelatihan_wrapper .card").length;
    let height_informasi_pelatihan = parseInt(informasi_pelatihan_wrapper.height());
    let height_informasi_pelatihan_content = height_informasi_pelatihan / informasi_pelatihan_length;
    height_informasi_pelatihan = height_informasi_pelatihan - height_informasi_pelatihan_content;
    let delt_informasi_pelatihan = 0;
    function scroll_li(delt_informasi_pelatihan) {
        informasi_pelatihan_wrapper.animate({
            top: delt_informasi_pelatihan
        }, 3000, "linear");
    }
    function run() {
        delt_informasi_pelatihan = (delt_informasi_pelatihan - height_informasi_pelatihan_content < -1 * height_informasi_pelatihan) ? height_informasi_pelatihan : delt_informasi_pelatihan - height_informasi_pelatihan_content;
        if (delt_informasi_pelatihan <= 0) {
            scroll_li(delt_informasi_pelatihan);
        } else if (delt_informasi_pelatihan === height_informasi_pelatihan) {
            informasi_pelatihan_wrapper.animate({
                top: delt_informasi_pelatihan
            }, 0, "linear");
            delt_informasi_pelatihan = 0;
            scroll_li(delt_informasi_pelatihan);
        }
        setTimeout(run, 7000);
    }
    setTimeout(run, 7000);
}

function runDetailPelatihan() {
    let detail_pelatihan_wrapper = $("#detail_pelatihan_wrapper");
    let detail_pelatihan_length = $("#detail_pelatihan_wrapper .card").length;
    let height_detail_pelatihan = parseInt(detail_pelatihan_wrapper.height());
    let height_detail_pelatihan_content = height_detail_pelatihan / detail_pelatihan_length;
    height_detail_pelatihan = height_detail_pelatihan - height_detail_pelatihan_content;
    let delt_detail_pelatihan = 0;
    function scroll_li(delt_detail_pelatihan) {
        detail_pelatihan_wrapper.animate({
            top: delt_detail_pelatihan
        }, 3000, "linear");
    }
    function run() {
        delt_detail_pelatihan = (delt_detail_pelatihan - height_detail_pelatihan_content < -1 * height_detail_pelatihan) ? height_detail_pelatihan : delt_detail_pelatihan - height_detail_pelatihan_content;
        if (delt_detail_pelatihan <= 0) {
            scroll_li(delt_detail_pelatihan);
        } else if (delt_detail_pelatihan === height_detail_pelatihan) {
            detail_pelatihan_wrapper.animate({
                top: delt_detail_pelatihan
            }, 0, "linear");
            delt_detail_pelatihan = 0;
            scroll_li(delt_detail_pelatihan);
        }
        setTimeout(run, 7000);
    }
    setTimeout(run, 7000);
}

function runJenisPelatihan() {
    let jenis_pelatihan_wrapper = $("#jenis_pelatihan_wrapper");
    let jenis_pelatihan_length = $("#jenis_pelatihan_wrapper .card").length;
    let height_jenis_pelatihan = parseInt(jenis_pelatihan_wrapper.height());
    let height_jenis_pelatihan_content = height_jenis_pelatihan / jenis_pelatihan_length;
    height_jenis_pelatihan = height_jenis_pelatihan - height_jenis_pelatihan_content;
    let delt_jenis_pelatihan = 0;
    function scroll_li(delt_jenis_pelatihan) {
        jenis_pelatihan_wrapper.animate({
            top: delt_jenis_pelatihan
        }, 3000, "linear");
    }
    function run() {
        delt_jenis_pelatihan = (delt_jenis_pelatihan - height_jenis_pelatihan_content < -1 * height_jenis_pelatihan) ? height_jenis_pelatihan : delt_jenis_pelatihan - height_jenis_pelatihan_content;
        if (delt_jenis_pelatihan <= 0) {
            scroll_li(delt_jenis_pelatihan);
        } else if (delt_jenis_pelatihan === height_jenis_pelatihan) {
            jenis_pelatihan_wrapper.animate({
                top: delt_jenis_pelatihan
            }, 0, "linear");
            delt_jenis_pelatihan = 0;
            scroll_li(delt_jenis_pelatihan);
        }
        setTimeout(run, 7000);
    }
    setTimeout(run, 7000);
}

function runSummaryPelatihan() {
    let summary_pelatihan_wrapper = $("#summary_pelatihan_wrapper");
    let summary_pelatihan_length = $("#summary_pelatihan_wrapper .card").length;
    let height_summary_pelatihan = parseInt(summary_pelatihan_wrapper.height());
    let height_summary_pelatihan_content = height_summary_pelatihan / summary_pelatihan_length;
    height_summary_pelatihan = height_summary_pelatihan - height_summary_pelatihan_content;
    let delt_summary_pelatihan = 0;
    function scroll_li(delt_summary_pelatihan) {
        summary_pelatihan_wrapper.animate({
            top: delt_summary_pelatihan
        }, 3000, "linear");
    }
    function run() {
        delt_summary_pelatihan = (delt_summary_pelatihan - height_summary_pelatihan_content < -1 * height_summary_pelatihan) ? height_summary_pelatihan : delt_summary_pelatihan - height_summary_pelatihan_content;
        if (delt_summary_pelatihan <= 0) {
            scroll_li(delt_summary_pelatihan);
        } else if (delt_summary_pelatihan === height_summary_pelatihan) {
            summary_pelatihan_wrapper.animate({
                top: delt_summary_pelatihan
            }, 0, "linear");
            delt_summary_pelatihan = 0;
            scroll_li(delt_summary_pelatihan);
        }
        setTimeout(run, 7000);
    }
    setTimeout(run, 7000);
}

function runCarouselPelatihan() {
    let carousel_pelatihan_wrapper = $("#carousel_pelatihan_wrapper");
    let carousel_pelatihan_length = $("#carousel_pelatihan_wrapper .card").length;
    let height_carousel_pelatihan = parseInt(carousel_pelatihan_wrapper.height());
    let height_carousel_pelatihan_content = height_carousel_pelatihan / carousel_pelatihan_length;
    height_carousel_pelatihan = height_carousel_pelatihan - height_carousel_pelatihan_content;
    let delt_carousel_pelatihan = 0;
    function scroll_li(delt_carousel_pelatihan) {
        carousel_pelatihan_wrapper.animate({
            top: delt_carousel_pelatihan
        }, 3000, "linear");
    }
    function run() {
        delt_carousel_pelatihan = (delt_carousel_pelatihan - height_carousel_pelatihan_content < -1 * height_carousel_pelatihan) ? height_carousel_pelatihan : delt_carousel_pelatihan - height_carousel_pelatihan_content;
        if (delt_carousel_pelatihan <= 0) {
            scroll_li(delt_carousel_pelatihan);
        } else if (delt_carousel_pelatihan === height_carousel_pelatihan) {
            carousel_pelatihan_wrapper.animate({
                top: delt_carousel_pelatihan
            }, 0, "linear");
            delt_carousel_pelatihan = 0;
            scroll_li(delt_carousel_pelatihan);
        }
        setTimeout(run, 7000);
    }
    setTimeout(run, 7000);
}

// function run() {
//     delta = (delta - height_li < -1 * height_ul) ? height_ul : delta - height_li;
//     if (delta <= 0) {
//         scroll_li(delta);
//     } else if (delta = height_ul) {
//         txt.animate({
//             top: delta
//         }, 0, "linear");
//         delta = 0;
//         scroll_li(delta);
//     }
//     setTimeout(run, 7000);
// }

// function scroll_li(delta) {
//     txt.animate({
//         top: delta
//     }, 3000, "linear");
// }