import React from 'react'
import { toAbsoluteUrl } from '../_metronic/helpers'

export default function Client() {
    return (
        <div className="card mb-4" style={{'height': '10vh'}}>
            <div className="card-body p-5 d-flex align-items-center">
                <marquee behavior="scroll" direction="left">
                    <div className="row">
                        <div className="col">
                            <img className="w-40px me-8" src={toAbsoluteUrl("/media/bdi/bdi-denpasar.jpeg")} alt="" />
                            <img className="w-40px me-8" src={toAbsoluteUrl("/media/bdi/bdi-jakarta.jpeg")} alt="" />
                            <img className="w-40px me-8" src={toAbsoluteUrl("/media/bdi/bdi-makassar.jpeg")} alt="" />
                            <img className="w-40px me-8" src={toAbsoluteUrl("/media/bdi/bdi-medan.jpeg")} alt="" />
                            <img className="w-40px me-8" src={toAbsoluteUrl("/media/bdi/bdi-padang.jpeg")} alt="" />
                            <img className="w-40px me-8" src={toAbsoluteUrl("/media/bdi/bdi-surabaya.jpeg")} alt="" />
                            <img className="w-40px me-8" src={toAbsoluteUrl("/media/bdi/bdi-yogyakarta.jpeg")} alt="" />
                        </div>
                    </div>
                </marquee>
            </div>
        </div>
    )
}