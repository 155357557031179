import React from 'react'

export default function Clock() {
    const [date, setDate] = React.useState(new Date())

    function refreshClock() {
        setDate(new Date())
    }

    React.useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId)
        }
    }, [])

    return (
        <div className="card pb-2 mb-4" height="100px">
            <div className="card-body py-0">
                <div className="row text-center">
                    <div className="fs-4 fw-bold">{date.toLocaleDateString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'})}</div>
                    <div className="col p-1">
                        <div className="card shadow-sm">
                            <div className="card-body py-2 d-flex flex-column">
                                <span className="fs-5 text-muted">WIB</span>
                                <span className="fs-4 fw-bold">{date.toLocaleString("en-US",{timeZone: 'Asia/Jakarta', timeStyle: 'short', hourCycle: 'h23'})}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="card shadow-sm">
                            <div className="card-body py-2 d-flex flex-column">
                                <span className="fs-5 text-muted">WITA</span>
                                <span className="fs-4 fw-bold">{date.toLocaleString("en-US",{timeZone: 'Asia/Ujung_Pandang', timeStyle: 'short', hourCycle: 'h23'})}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="card shadow-sm">
                            <div className="card-body py-2 d-flex flex-column">
                                <span className="fs-5 text-muted">WIT</span>
                                <span className="fs-4 fw-bold">{date.toLocaleString("en-US",{timeZone: 'Asia/Jayapura', timeStyle: 'short', hourCycle: 'h23'})}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}