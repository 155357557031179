import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { toAbsoluteUrl } from '../_metronic/helpers'

export default function Dokumentasi(props) {
    const items =  props.items
    const cardDokumentasi = items.map(arr => (
        <div key={arr.id_diklat} className="card mb-4 shadow-sm carousel_pelatihan">
            <div className="card-body p-3">
                <Carousel>
                    {arr.dokumentasi.length > 0 ? arr.dokumentasi.map((dokumentasi, index) => (
                        <Carousel.Item key={index}>
                        <div className="ratio ratio-16x9">
                            <img className='ratio ratio-16x9' src={dokumentasi.file} alt=""/>
                        </div>
                        <Carousel.Caption>
                        <h3 className='text-white'>{dokumentasi.kategori}</h3>
                        <p className='carousel-pelatihan--deskripsi-dokumentasi'>{dokumentasi.deskripsi}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    )) : (
                        <Carousel.Item>
                        <div className="ratio ratio-16x9">
                            <img className='ratio ratio-16x9' src={toAbsoluteUrl('/media/misc/no-image.png')} alt=""/>
                        </div>
                    </Carousel.Item>
                    )}
                    {arr.youtube && arr.youtube !== "" ? (
                        <Carousel.Item>
                            <div className="ratio ratio-16x9">
                                <iframe src="https://www.youtube.com/embed/_c5iXbp9vJY" title="YouTube video" allowFullScreen></iframe>
                            </div>
                        </Carousel.Item>
                    ) : null}
                </Carousel>
            </div>
        </div>
    ))

    return (
        <div className="card mb-4 overflow-hidden" style={{'height': '40vh'}}>
            <div className="card-body py-1 px-0">
                <div className="d-flex flex-column marquee" id="carousel_pelatihan_wrapper" data-duplicated='true' data-direction='left'>
                    {/* marquee */}
                    {/* <div className="card mb-4 shadow-sm carousel_pelatihan" id="carousel_pelatihan_satu">
                        <div className="card-body p-3">
                            <Carousel>
                                <Carousel.Item>
                                    <div className="ratio ratio-16x9">
                                        <img src={toAbsoluteUrl('/media/kegiatan/28a1a113c9de936faf2774ed4945e9b1.jpeg')} alt="" />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="ratio ratio-16x9">
                                        <iframe src="https://www.youtube.com/embed/_c5iXbp9vJY" title="YouTube video" allowFullScreen></iframe>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div> */}
                    {cardDokumentasi}
                    {/* marquee */}
                </div>
            </div>
        </div>
    )
}