import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'


export default function PesertaModal(props) {
    let dataPeserta
    let nama
    let pageCount = 0
    const [pageNumber, setPageNumber] = useState(0)
    
    const changePage = ({selected}) => {
        setPageNumber(selected)
    }

    const resetPage = () => {
        setPageNumber(0)
    }

    const pesertaPerPage = 14
    const pagesVisited = pageNumber * pesertaPerPage
    
    if(props.items !== null) {
        const items = props.items
        pageCount = Math.ceil(items.length / pesertaPerPage)
        dataPeserta = items
            .slice(pagesVisited, pagesVisited + pesertaPerPage)
            .map(item => (
                <Link key={item.id_peserta} to={'/detil_peserta/' + item.id_peserta}>
                    <div className="d-flex flex-center flex-column w-150px">
                        <div className="symbol mb-5">
                            <img src={item.foto} alt="peserta"/>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <div style={{'display' : 'none'}}>{nama = item.nama.split(' ')}</div>
                            <span className="fs-7 text-gray-800 text-hover-primary text-center fw-bold">{nama[0]} {nama[1]}</span>
                            <span className="fw-semibold text-gray-400 text-center">{item.umur} tahun</span>
                            <span className="fw-semibold text-gray-400 mb-6 text-center">{item.alamat['kota']}</span>
                        </div>
                    </div>
                </Link>
            ))
    }


    return (
       <Modal 
        size='xl'
        show={props.show} 
        onHide={() => {props.handleModal(); setTimeout(resetPage, 1000)}}
        className='modal-peserta'
        >
        <Modal.Header closeButton>
          <Modal.Title>Daftar Peserta</Modal.Title>
        </Modal.Header>
        <Modal.Body
        className='overflow-auto'
        >
            <div className="d-flex flex-wrap justify-content-around mb-2">
                {/* <Link to='/detil_peserta'>
                    <div className="d-flex flex-center flex-column">
                        <div className="symbol mb-5">
                            <img src={toAbsoluteUrl("/media/avatars/150-1.jpg")} alt="peserta"/>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <span className="fs-4 text-gray-800 text-hover-primary fw-bold">Nama Peserta</span>
                            <span className="fw-semibold text-gray-400">24 tahun</span>
                            <span className="fw-semibold text-gray-400 mb-6">Penempatan : Jakarta</span>
                        </div>
                    </div>
                </Link> */}
                {dataPeserta ? dataPeserta : 'Loading'}
            </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
            {/* <div>
                <ul className="pagination">
                    <li className="page-item previous disabled">
                        <a href=".." className="page-link">
                        <i className="previous"></i>
                        </a>
                    </li>
                    <li className="page-item">
                        <a href=".." className="page-link">
                        1
                        </a>
                    </li>
                    <li className="page-item active">
                        <a href=".." className="page-link">
                        2
                        </a>
                    </li>
                    <li className="page-item">
                        <a href=".." className="page-link">
                        3
                        </a>
                    </li>
                    <li className="page-item">
                        <a href=".." className="page-link">
                        4
                        </a>
                    </li>
                    <li className="page-item">
                        <a href=".." className="page-link">
                        5
                        </a>
                    </li>
                    <li className="page-item">
                        <a href=".." className="page-link">
                        6
                        </a>
                    </li>
                    <li className="page-item next">
                        <a href=".." className="page-link">
                        <i className="next"></i>
                        </a>
                    </li>
                    </ul>
            </div> */}
            <ReactPaginate 
                nextLabel={'>'}
                pageCount={pageCount}
                onPageChange={changePage}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </Modal.Footer>
      </Modal>
    )
}