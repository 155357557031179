import React from 'react'
import { toAbsoluteUrl } from '../_metronic/helpers'
import PesertaModal from './modals/Peserta'
import InstrukturModal from './modals/Instruktur'
import { loadData } from '../data/data'

export default function DetilPelatihan(props) {
    const [showPeserta, setShowPeserta] = React.useState(false)
    const handleModalPeserta = () => setShowPeserta(!showPeserta)
    
    const [showInstruktur, setShowInstruktur] = React.useState(false)
    const handleModalInstruktur = () => setShowInstruktur(!showInstruktur)

    const [dataInstruktur, setDataInstruktur] = React.useState([])
    const handleDataInstruktur = (instruktur) => setDataInstruktur(instruktur)

    const [dataPeserta, setDataPeserta] = React.useState(null)

    const loadDataPeserta = (id_diklat) => {
        setDataPeserta(null)
        loadData("https://api.sidia.my.id/srv/diklat/peserta", {'id_diklat': id_diklat}, setDataPeserta)
    }

    const items = props.items
    const detilPelatihan = items.map(item => (
        <div key={item.id_diklat} className="card mb-4 shadow-sm detail_pelatihan" id="detail_pelatihan_satu">
            <div className="card-body py-1">
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Lokasi</div>
                    <div className="col detil-pelatihan--lokasi">{item.tempat}</div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Jumlah Peserta</div>
                    <div className="col">{item.jumlah_peserta} Orang</div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Instruktur</div>
                    <div className="col d-flex">
                        {item.pemateri.map(pemateri => (
                            <img key={pemateri.ktp} src={toAbsoluteUrl('/media/avatars/placeholder_profile_pict.jpeg')} alt="" className="instruktur-profile" width={'20px'} onClick={() => {handleModalInstruktur(); handleDataInstruktur(pemateri) }}/> 
                        ))}
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Daftar Peserta</div>
                    <div className="col d-flex cursor-pointer">
                        <span className='text-primary' onClick={() => {handleModalPeserta(); loadDataPeserta(item.id_diklat)}}>Lihat Daftar Peserta</span>
                    </div>
                </div>
            </div>
        </div>
        )   
    )


    return (
        <div className="card mb-4 overflow-hidden" style={{'height': '29vh'}}>
            <div className="card-header bg-white" style={{'minHeight': '50px', 'zIndex': '2'}}>
                <div className="card-title">Detil Pelatihan</div>
            </div>
            <div className="card-body py-2 px-0">
                <div className="d-flex flex-column marquee" id="detail_pelatihan_wrapper" data-duplicated='true' data-direction='left'>
                    {/* marquee */}
                    {detilPelatihan}
                    {/* marquee */}
                </div>
            </div>
            <PesertaModal 
            handleModal={handleModalPeserta}
            show={showPeserta}
            items={dataPeserta}
          />
          <InstrukturModal
            handleModal={handleModalInstruktur}
            show={showInstruktur}
            items={dataInstruktur}
          />
        </div>
    )
}