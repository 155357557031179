import React from 'react'

export default function Summary(props) {
    const items = props.items
    const summary = items.map(item => (
        <div key={item.id_diklat} className="card mb-4 shadow-sm summary_pelatihan" style={{'height' : '145px'}}>
            <div className="card-body py-1">
                {item.deskripsi && item.deskripsi !== "" ? item.deskripsi : 'Tidak ada rangkuman/brief materi'}
            </div>
        </div>
    ))
    return (
        <div className="card mb-4 overflow-hidden" style={{'height': '29vh'}}>
            <div className="card-header bg-white" style={{'minHeight': '50px', 'zIndex': '2'}}>
                <div className="card-title">Summary/Brief Materi</div>
            </div>
            <div className="card-body py-2 px-0">
                <div className="d-flex flex-column marquee" id="summary_pelatihan_wrapper" data-duplicated='true' data-direction='left'>
                    {/* marquee */}
                    {summary}
                    {/* marquee */}
                </div>
            </div>
        </div>
    )
}