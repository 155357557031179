import axios from "axios"

export function loadData (url, params, setState) {
    const auth = {
        username: 'api_sidia',
        password: 'wzFSZH>!h8Z3z8bF'
    }

    const header = {
        'SRV-KEY': 'nG!L6%=;',
        ...params
    }

    axios.post(url, header, {'auth' : auth})
        .then(function (response) {
            setState(response.data.data)
        })
        .catch(function (error) {
            console.log(error)
        });
}