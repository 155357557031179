import React from 'react'
import { toAbsoluteUrl } from '../../_metronic/helpers';
import { Modal } from 'react-bootstrap';
import { dateFunc, getAge } from '../../_metronic/helpers/general_helper';


export default function InstrukturModal(props) {

    let dataInstruktur = props.items

    return (
        <Modal 
        size='xl'
        show={props.show} 
        onHide={props.handleModal}>
            <Modal.Header closeButton>
                <Modal.Title>Detail Instruktur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body">
                    <div className="card mb-5 mb-xxl-8">
                        <div className="card-body pt-9 pb-0">
                            {/* <!--begin::Details--> */}
                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                {/* <!--begin: Pic--> */}
                                <div className="me-7 mb-4">
                                    <div className="symbol symbol-100px symbol-lg-130px symbol-fixed position-relative">
                                        <img src={toAbsoluteUrl("/media/avatars/placeholder_profile_pict.jpeg")} alt="instruktur" />
                                    </div>
                                </div>
                                {/* <!--end::Pic--> */}
                                {/* <!--begin::Info--> */}
                                <div className="flex-grow-1">
                                    {/* <!--begin::Title--> */}
                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                        {/* <!--begin::User--> */}
                                        <div className="d-flex flex-column">
                                            {/* <!--begin::Name--> */}
                                            <div className="d-flex align-items-center mb-2">
                                                <span className="text-gray-900 fs-2 fw-bolder me-1">{dataInstruktur.nama}</span>
                                            </div>
                                            {/* <!--end::Name--> */}
                                            {/* <!--begin::Info--> */}
                                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                <span className="d-flex align-items-center text-gray-400 me-5 mb-2">
                                                    {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                                    <span className="svg-icon svg-icon-4 me-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none">
                                                            <path opacity="0.3"
                                                                d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                                                fill="black" />
                                                            <path
                                                                d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                                                fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/* <!--end::Svg Icon--> */}
                                                    {dataInstruktur.instansi ? dataInstruktur.instansi['jabatan'] : null}
                                                </span>
                                                <span className="d-flex align-items-center text-gray-400 me-5 mb-2">
                                                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                                    <span className="svg-icon svg-icon-4 me-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none">
                                                            <path opacity="0.3"
                                                                d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                                fill="black" />
                                                            <path
                                                                d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                                fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/* <!--end::Svg Icon-->*/}
                                                    {dataInstruktur.instansi ? dataInstruktur.instansi['provinsi'] : null}
                                                </span>
                                                <span className="d-flex align-items-center text-gray-400 mb-2">
                                                    {/* <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg--> */}
                                                    <span className="svg-icon svg-icon-4 me-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none">
                                                            <path opacity="0.3"
                                                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                                fill="black" />
                                                            <path
                                                                d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                                fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/* <!--end::Svg Icon--> */}
                                                    {dataInstruktur.email}
                                                </span>
                                            </div>
                                            {/* <!--end::Info--> */}
                                        </div>
                                        {/* <!--end::User--> */}
                                    </div>
                                    {/* <!--end::Title--> */}
                                    {/* <!--begin::Stats--> */}
                                    <div className="d-flex flex-wrap flex-stack">
                                        {/* <!--begin::Wrapper--> */}
                                        <div className="d-flex flex-column flex-grow-1 pe-8">
                                            {/* <!--begin::Stats--> */}
                                            {dataInstruktur.instansi ? (
                                                <div className="row mb-7">
                                                {/* <!--begin::Label--> */}
                                                <label className="col-lg-2 fw-bold text-muted">Perusahaan</label>
                                                {/* <!--end::Label--> */}
                                                {/* <!--begin::Col--> */}
                                                <div className="col-lg-10">
                                                    <span className="fw-bolder fs-6 text-gray-800">{dataInstruktur.instansi['nama']}</span>
                                                </div>
                                                {/* <!--end::Col--> */}
                                            </div>
                                            ) : null}
                                            {/* <!--end::Row--> */}
                                            {/* <!--end::Stats--> */}
                                        </div>
                                        {/* <!--end::Wrapper--> */}
                                    </div>
                                    {/* <!--end::Stats--> */}
                                </div>
                                {/* <!--end::Info--> */}
                            </div>
                            {/* <!--end::Details--> */}
                        </div>
                    </div>
                    {/* <!--end::Navbar--> */}
                    {/* <!--begin::details View--> */}
                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                        {/* <!--begin::Card header--> */}
                        <div className="card-header cursor-pointer">
                            {/* <!--begin::Card title--> */}
                            <div className="card-title m-0">
                                <h3 className="fw-bolder m-0">Profil Pribadi</h3>
                            </div>
                            {/* <!--end::Card title--> */}
                        </div>
                        {/* <!--begin::Card header--> */}
                        {/* <!--begin::Card body--> */}
                        <div className="card-body p-9">
                            {/* <!--begin::Row--> */}
                            <div className="row mb-7">
                                {/* <!--begin::Label--> */}
                                <label className="col-lg-4 fw-bold text-muted">Nomor KTP / NIK</label>
                                {/* <!--end::Label--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-lg-8">
                                    <span className="fw-bolder fs-6 text-gray-800">{dataInstruktur.ktp}</span>
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            {dataInstruktur.npwp ? (
                                <div className="row mb-7">
                                {/* <!--begin::Label--> */}
                                <label className="col-lg-4 fw-bold text-muted">NPWP</label>
                                {/* <!--end::Label--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-lg-8">
                                    <span className="fw-bolder fs-6 text-gray-800">85.277.884-4-402.000</span>
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            ) : null}
                            <div className="row mb-7">
                                {/* <!--begin::Label--> */}
                                <label className="col-lg-4 fw-bold text-muted">Jenis Kelamin</label>
                                {/* <!--end::Label--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-lg-8">
                                    <span className="fw-bolder fs-6 text-gray-800">{dataInstruktur.jenis_kelamin}</span>
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            {/* <!--end::Row--> */}
                            {/* <!--begin::Row--> */}
                            <div className="row mb-7">
                                {/* <!--begin::Label--> */}
                                <label className="col-lg-4 fw-bold text-muted">Tempat Tanggal Lahir</label>
                                {/* <!--end::Label--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-lg-8">
                                    <span className="fw-bolder fs-6 text-gray-800">{dataInstruktur.tempat_lahir}, {dateFunc(dataInstruktur.tanggal_lahir)}</span>
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            <div className="row mb-7">
                                {/* <!--begin::Label--> */}
                                <label className="col-lg-4 fw-bold text-muted">Umur</label>
                                {/* <!--end::Label--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-lg-8">
                                    <span className="fw-bolder fs-6 text-gray-800">{getAge(dataInstruktur.tanggal_lahir)} tahun</span>
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            {/* <!--end::Row--> */}
                            {dataInstruktur.agama ? (
                                <div className="row mb-7">
                                {/* <!--begin::Label--> */}
                                <label className="col-lg-4 fw-bold text-muted">Agama</label>
                                {/* <!--end::Label--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-lg-8">
                                    <span className="fw-bolder fs-6 text-gray-800">Islam</span>
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            ) : null}
                            {/* <!--end::Row--> */}
                            
                            {/* <!--end::Input group--> */}
                            {/* <!--begin::Input group--> */}
                            <div className="row mb-7">
                                {/* <!--begin::Label--> */}
                                <label className="col-lg-4 fw-bold text-muted">Alamat</label>
                                {/* <!--end::Label--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-lg-8">
                                    <span className="fw-bolder fs-6 text-gray-800">{dataInstruktur.alamat}</span>
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            {/* <!--end::Input group--> */}
                            {/* <!--begin::Input group--> */}
                            <div className="row mb-7">
                                {/* <!--begin::Label--> */}
                                <label className="col-lg-4 fw-bold text-muted">Nomor Handphone
                                    <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip"
                                        title="Nomor kontak yang terdaftar wajib aktif"></i>
                                </label>
                                {/* <!--end::Label--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-lg-8 d-flex align-items-center">
                                    <span className="fw-bolder fs-6 text-gray-800 me-2">{dataInstruktur.mobile}</span>
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            {/* <!--end::Input group--> */}
                            {/* <!--begin::Input group--> */}
                            <div className="row mb-7">
                                {/* <!--begin::Label--> */}
                                <label className="col-lg-4 fw-bold text-muted">Email
                                    <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip"
                                        title="Email yang terdaftar wajib aktif"></i>
                                </label>
                                {/* <!--end::Label--> */}
                                {/* <!--begin::Col--> */}
                                <div className="col-lg-8 fv-row">
                                    <span className="fw-bolder text-gray-800 fs-6">{dataInstruktur.email ? dataInstruktur.email : '-'}</span>
                                </div>
                                {/* <!--end::Col--> */}
                            </div>
                            {/* <!--end::Input group--> */}
                        </div>
                        {/* <!--end::Card body--> */}
                    </div>
                    {/* <!--end::details View--> */}
                    {dataInstruktur.jenjang_pendidikan ? (
                        <div className="row g-5 g-xxl-8">
                        {/* <!--begin::Col--> */}
                        {/* <!--begin::Col--> */}
                        <div className="col-xl-12">
                            {/* <!--begin::List Widget 5--> */}
                            <div className="card mb-5 mb-xxl-8">
                                {/* <!--begin::Body--> */}
                                <div className="card-body pt-5">
                                    {/* <!--begin::Timeline--> */}
                                    {/* <!--begin::Item--> */}
                                    <div className="m-0">
                                        {/* <!--begin::Wrapper--> */}
                                        <div className="d-flex align-items-sm-center mb-5">
                                            {/* <!--begin::Symbol--> */}
                                            <div className="symbol symbol-45px me-4">
                                                <span className="symbol-label bg-primary">
                                                    {/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
                                                    <span className="svg-icon svg-icon-2x svg-icon-white">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.3"
                                                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                                fill="currentColor"></path>
                                                            <path
                                                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                                fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    {/* <!--end::Svg Icon--> */}
                                                </span>
                                            </div>
                                            {/* <!--end::Symbol--> */}
                                            {/* <!--begin::Section--> */}
                                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                <div className="flex-grow-1 me-2">
                                                    <span className="text-gray-800 fw-bold d-block fs-4">Riwayat Pendidikan</span>
                                                </div>
                                            </div>
                                            {/* <!--end::Section--> */}
                                        </div>
                                        {/* <!--end::Wrapper--> */}
                                        {/* <!--begin::Timeline--> */}
                                        <div className="timeline">
                                            <div className="timeline-label">
                                                {/* <!--begin::Item--> */}
                                                <div className="timeline-item">
                                                    {/* <!--begin::Label--> */}
                                                    <div className="timeline-label fw-bold text-gray-800 fs-6">2005 - 2008</div>
                                                    {/* <!--end::Label--> */}
                                                    {/* <!--begin::Badge--> */}
                                                    <div className="timeline-badge">
                                                        <i className="fa fa-genderless text-primary fs-1"></i>
                                                    </div>
                                                    {/* <!--end::Badge--> */}
                                                    {/* <!--begin::Text--> */}
                                                    <div className="timeline-content fw-mormal text-muted ps-3">D3 Politeknik Pelayaran
                                                        Surabaya, Jurusan Elektro Pelayaran </div>
                                                    {/* <!--end::Text--> */}
                                                </div>
                                                {/* <!--end::Item--> */}
                                                {/* <!--begin::Item--> */}
                                                <div className="timeline-item">
                                                    {/* <!--begin::Label--> */}
                                                    <div className="timeline-label fw-bold text-gray-800 fs-6">2002 - 2005</div>
                                                    {/* <!--end::Label--> */}
                                                    {/* <!--begin::Badge--> */}
                                                    <div className="timeline-badge">
                                                        <i className="fa fa-genderless text-primary fs-1"></i>
                                                    </div>
                                                    {/* <!--end::Badge--> */}
                                                    {/* <!--begin::Text--> */}
                                                    <div className="timeline-content fw-mormal text-muted ps-3">SMA Negeri 5 Jakarta </div>
                                                    {/* <!--end::Text--> */}
                                                </div>
                                                {/* <!--end::Item--> */}
                                                {/* <!--begin::Item--> */}
                                                <div className="timeline-item">
                                                    {/* <!--begin::Label--> */}
                                                    <div className="timeline-label fw-bold text-gray-800 fs-6">1999 - 2002</div>
                                                    {/* <!--end::Label--> */}
                                                    {/* <!--begin::Badge--> */}
                                                    <div className="timeline-badge">
                                                        <i className="fa fa-genderless text-primary fs-1"></i>
                                                    </div>
                                                    {/* <!--end::Badge--> */}
                                                    {/* <!--begin::Text--> */}
                                                    <div className="timeline-content fw-mormal text-muted ps-3">SMP Negeri 93 Jakarta Pusat
                                                    </div>
                                                    {/* <!--end::Text--> */}
                                                </div>
                                                {/* <!--end::Item--> */}
                                                {/* <!--begin::Item--> */}
                                                <div className="timeline-item">
                                                    {/* <!--begin::Label--> */}
                                                    <div className="timeline-label fw-bold text-gray-800 fs-6">1993 - 1999</div>
                                                    {/* <!--end::Label--> */}
                                                    {/* <!--begin::Badge--> */}
                                                    <div className="timeline-badge">
                                                        <i className="fa fa-genderless text-primary fs-1"></i>
                                                    </div>
                                                    {/* <!--end::Badge--> */}
                                                    {/* <!--begin::Text--> */}
                                                    <div className="timeline-content fw-mormal text-muted ps-3">Sekolah Dasar Negeri Kebon
                                                        Kosong 13 Pagi</div>
                                                    {/* <!--end::Text--> */}
                                                </div>
                                                {/* <!--end::Item--> */}
                                            </div>
                                        </div>
                                        {/* <!--end::Timeline--> */}
                                    </div>
                                    {/* <!--end::Item--> */}
                                    {/* <!--end::Timeline--> */}
                                </div>
                                {/* <!--end: Card Body--> */}
                            </div>
                            {/* <!--end: List Widget 5--> */}
                        </div>
                        {/* <!--end::Col--> */}
                        {/* <!--end::Col--> */}
                    
                    </div>
                    ) : null}
                    {dataInstruktur.jenjang_pekerjaan ? (
                        <div className="row g-5 g-xxl-8">
                        {/* <!--begin::Col--> */}
                        <div className="col-xl-12">
                            {/* <!--begin::List Widget 5--> */}
                            <div className="card mb-5 mb-xxl-8">
                                {/* <!--begin::Body--> */}
                                <div className="card-body pt-5">
                                    {/* <!--begin::Timeline--> */}
                                    {/* <!--begin::Item--> */}
                                    <div className="m-0">
                                        {/* <!--begin::Wrapper--> */}
                                        <div className="d-flex align-items-sm-center mb-5">
                                            {/* <!--begin::Symbol--> */}
                                            <div className="symbol symbol-45px me-4">
                                                <span className="symbol-label bg-primary">
                                                    {/* <!--begin::Svg Icon | path: icons/duotune/art/art005.svg--> */}
                                                    <span className="svg-icon svg-icon-2x svg-icon-white">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.3"
                                                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                                fill="currentColor"></path>
                                                            <path
                                                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                                fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    {/* <!--end::Svg Icon--> */}
                                                </span>
                                            </div>
                                            {/* <!--end::Symbol--> */}
                                            {/* <!--begin::Section--> */}
                                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                <div className="flex-grow-1 me-2">
                                                    <span className="text-gray-800 fw-bold d-block fs-4">Riwayat Pekerjaan</span>
                                                </div>
                                            </div>
                                            {/* <!--end::Section--> */}
                                        </div>
                                        {/* <!--end::Wrapper--> */}
                                        {/* <!--begin::Timeline--> */}
                                        <div className="timeline">
                                            {/* <!--begin::Timeline item--> */}
                                            <div className="timeline-item align-items-center mb-7">
                                                {/* <!--begin::Timeline line--> */}
                                                <div className="timeline-line w-40px mt-6 mb-n12"></div>
                                                {/* <!--end::Timeline line--> */}
                                                {/* <!--begin::Timeline icon--> */}
                                                <div className="timeline-icon" style={{'marginLeft': '11px'}}>
                                                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen015.svg--> */}
                                                    <span className="svg-icon svg-icon-2 svg-icon-danger">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.3"
                                                                d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM6.39999 9.89999C6.99999 8.19999 8.40001 6.9 10.1 6.4C10.6 6.2 10.9 5.7 10.7 5.1C10.5 4.6 9.99999 4.3 9.39999 4.5C7.09999 5.3 5.29999 7 4.39999 9.2C4.19999 9.7 4.5 10.3 5 10.5C5.1 10.5 5.19999 10.6 5.39999 10.6C5.89999 10.5 6.19999 10.2 6.39999 9.89999ZM14.8 19.5C17 18.7 18.8 16.9 19.6 14.7C19.8 14.2 19.5 13.6 19 13.4C18.5 13.2 17.9 13.5 17.7 14C17.1 15.7 15.8 17 14.1 17.6C13.6 17.8 13.3 18.4 13.5 18.9C13.6 19.3 14 19.6 14.4 19.6C14.5 19.6 14.6 19.6 14.8 19.5Z"
                                                                fill="currentColor"></path>
                                                            <path
                                                                d="M16 12C16 14.2 14.2 16 12 16C9.8 16 8 14.2 8 12C8 9.8 9.8 8 12 8C14.2 8 16 9.8 16 12ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                                                fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    {/* <!--end::Svg Icon--> */}
                                                </div>
                                                {/* <!--end::Timeline icon--> */}
                                                {/* <!--begin::Timeline content--> */}
                                                <div className="timeline-content m-0">
                                                    <div className="table-responsive">
                                                        {/* <!--begin::Table--> */}
                                                        <table
                                                            className="table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5"
                                                            id="kt_table_customers_logs">
                                                            {/* <!--begin::Table body--> */}
                                                            <tbody>
                                                                {/* <!--begin::Table row--> */}
                                                                <tr>
                                                                    {/* <!--begin::Badge=--> */}
                                                                    <td className="min-w-200px">
                                                                        <span className="fs-6 fw-bold text-gray-800">PT.Barata Indonesia</span>
                                                                        <span className="fs-6 fw-bold text-gray-800">2019 -2022</span>
                                                                    </td>
                                                                    {/* <!--end::Badge=--> */}
                                                                    {/* <!--begin::Status=--> */}
                                                                    {/* <!-- <td> */}
                                                                        {/* <span className="fs-6 fw-bold text-gray-800">Nama Perusahaan</span></br> */}
                                                                        {/* <span className="fs-6 text-gray-400 fw-semibold d-block">Lorem Ipsum is */}
                                                                            {/* simply dummy text of the printing and typesetting industry. */}
                                                                            {/* Lorem Ipsum has been the industry's standard dummy text ever */}
                                                                            {/* since the 1500s, when an unknown printer took a galley of type */}
                                                                            {/* and scrambled it to make a type specimen book. </span> */}
                                                                    {/* </td> --> */}
                                                                    {/* <!--end::Status=--> */}
                                                                </tr>
                                                                {/* <!--end::Table row--> */}
                                                            </tbody>
                                                            {/* <!--end::Table body--> */}
                                                        </table>
                                                        {/* <!--end::Table--> */}
                                                    </div>
                                                </div>
                                                {/* <!--end::Timeline content--> */}
                                            </div>
                                            {/* <!--end::Timeline item--> */}
                                        </div>
                                        {/* <!--end::Timeline--> */}
                                    </div>
                                    {/* <!--end::Item--> */}
                                    {/* <!--end::Timeline--> */}
                                </div>
                                {/* <!--end: Card Body--> */}
                            </div>
                            {/* <!--end: List Widget 5--> */}
                        </div>
                        {/* <!--end::Col--> */}
                    </div>
                    ) : null}
                    {dataInstruktur.instansi ? (
                        <div className="row g-5 g-xxl-8">
                        {/* <!--begin::Col--> */}
                        <div className="col-xl-12">
                            <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                {/* <!--begin::Card header--> */}
                                <div className="card-header cursor-pointer">
                                    {/* <!--begin::Card title--> */}
                                    <div className="card-title m-0">
                                        <h3 className="fw-bolder m-0">Informasi Tempat Instansi Bekerja</h3>
                                    </div>
                                    {/* <!--end::Card title--> */}
                                </div>
                                {/* <!--begin::Card header--> */}
                                {/* <!--begin::Card body--> */}
                                <div className="card-body p-9">
                                    {/* <!--begin::Row--> */}
                                    <div className="row mb-7">
                                        {/* <!--begin::Label--> */}
                                        <label className="col-lg-4 fw-bold text-muted">Nama Instansi</label>
                                        {/* <!--end::Label--> */}
                                        {/* <!--begin::Col--> */}
                                        <div className="col-lg-8">
                                            <span className="fw-bolder fs-6 text-gray-800">{dataInstruktur.instansi['nama']}</span>
                                        </div>
                                        {/* <!--end::Col--> */}
                                    </div>
                                    <div className="row mb-7">
                                        {/* <!--begin::Label--> */}
                                        <label className="col-lg-4 fw-bold text-muted">Jabatan</label>
                                        {/* <!--end::Label--> */}
                                        {/* <!--begin::Col--> */}
                                        <div className="col-lg-8">
                                            <span className="fw-bolder fs-6 text-gray-800">{dataInstruktur.instansi['jabatan']}</span>
                                        </div>
                                        {/* <!--end::Col--> */}
                                    </div>
                                    <div className="row mb-7">
                                        {/* <!--begin::Label--> */}
                                        <label className="col-lg-4 fw-bold text-muted">Alamat</label>
                                        {/* <!--end::Label--> */}
                                        {/* <!--begin::Col--> */}
                                        <div className="col-lg-8">
                                            <span className="fw-bolder fs-6 text-gray-800">{dataInstruktur.instansi['alamat']}</span>
                                        </div>
                                        {/* <!--end::Col--> */}
                                    </div>
                                    {/* <!--end::Row--> */}
                                    {/* <!--begin::Row--> */}
                                    <div className="row mb-7">
                                        {/* <!--begin::Label--> */}
                                        <label className="col-lg-4 fw-bold text-muted">Telepon Instansi</label>
                                        {/* <!--end::Label--> */}
                                        {/* <!--begin::Col--> */}
                                        <div className="col-lg-8">
                                            <span className="fw-bolder fs-6 text-gray-800">{dataInstruktur.instansi['telepon']}</span>
                                        </div>
                                        {/* <!--end::Col--> */}
                                    </div>
                                </div>
                                {/* <!--end::Card body--> */}
                            </div>
                        </div>
                    </div>
                    ) : null}
                    {/* <!--end::Row--> */}
                </div>
            </Modal.Body>
      </Modal>
    )
}