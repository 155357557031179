import React from 'react'
import { dateFunc } from '../_metronic/helpers/general_helper'

export default function InfoPelatihan(props) {
    const items = props.items
    const infoPelatihan = items.map(item => (
        <div key={item.id_diklat} className="card mb-4 shadow-sm informasi_pelatihan" id="informasi_pelatihan_satu">
            <div className="card-body py-1" style={{'height' : '120px'}}>
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Nama Diklat</div>
                    <div className="col info-pelatihan--nama-diklat">{item.nama}</div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Tanggal</div>
                    <div className="col">{dateFunc(item.tanggal_mulai)} - {dateFunc(item.tanggal_selesai)}</div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Materi</div>
                    <div className="col">{item.jumlah_materi} MD / {item.jumlah_jpl} JPL</div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Penyelenggara</div>
                    <div className="col info-pelatihan--penyelenggara">{item.penyelenggara}</div>
                </div>
            </div>
        </div>
    ))
    return (
        <div className="card mb-4 overflow-hidden" style={{'height': '25vh'}}>
            <div className="card-header bg-white" style={{'minHeight': '50px', 'zIndex': '2'}}>
                <div className="card-title">Informasi Pelatihan</div>
            </div>
            <div className="card-body py-2 px-0">
                <div className="d-flex flex-column marquee" id="informasi_pelatihan_wrapper" data-duplicated='true' data-direction='left'>
                    {/* marquee */}
                    {infoPelatihan}
                    {/* marquee */}
                </div>
            </div>
        </div>
    )
}