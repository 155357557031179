import React from 'react'

export default function JenisPelatihan(props) {
    const items = props.items
    const jenisPelatihan = items.map(item => (
        <div key={item.id_diklat} className="card mb-4 shadow-sm jenis_pelatihan" id="jenis_pelatihan_satu">
            <div className="card-body py-1">
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Jenis Pelatihan</div>
                    <div className="col">{item.jenis_diklat_alt}</div>
                </div>
                <div className="row mb-2">
                    <div className="col-4 fw-bolder">Jenjang Kompetensi</div>
                    <div className="col jenis-pelatihan--jenjang-kompetensi">{item.uraian_skema} [{item.kompetensi}]</div>
                </div>
            </div>
        </div>
    ))
    return (
        <div className="card overflow-hidden mb-4" style={{'height': '10vh'}}>
            <div className="card-body py-0 px-0">
                <div className="d-flex flex-column marquee" id="jenis_pelatihan_wrapper" data-duplicated='true' data-direction='left'>
                    {/* marquee */}
                    {jenisPelatihan}
                    {/* marquee */}
                </div>
            </div>
        </div>
    )
}