import React from 'react'
import { Content } from '../_metronic/layout/components/Content'
import Clock from './Clock'
import InfoPelatihan from './InfoPelatihan'
import DetilPelatihan from './DetilPelatihan'
import JenisPelatihan from './JenisPelatihan'
import Summary from './Summary'
import Dokumentasi from './Dokumentasi'
import Client from './Client'
import PelatihanMendatang from './PelatihanMendatang'
import Kontak from './Kontak'
import '../assets/css/Dashboard.css?v=1.0.6'
import { loadData } from '../data/data'
export default function Dashboard() {

    // Set Cron Job
    setInterval(function() {
      const now = new Date()
      if(now.getHours() === 6 && now.getMinutes() === 0) {
        window.location.reload()
      }
    }, 60000)
    
    const [currPelatihan, setCurrPelatihan] = React.useState(null)
    const [pelatihanMendatang, setPelatihanMendatang] = React.useState(null)
    const [pelatihanSelesai, setPelatihanSelesai] =  React.useState(null)
    
    React.useEffect(() => {
      pelatihanOngoing()
      pelatihanAvailable()
      pelatihanFinished()
      // console.log(data)
    }, [])

    const pelatihanOngoing = () => {
      loadData("https://api.sidia.my.id/srv/diklat", {'tahun': new Date().getFullYear(), "event_status":"ongoing"}, setCurrPelatihan)
    }

    const pelatihanAvailable = () => {
      loadData("https://api.sidia.my.id/srv/diklat", {'tahun': new Date().getFullYear(), "event_status":"available" }, setPelatihanMendatang)
    }

    const pelatihanFinished = () => {
      loadData("https://api.sidia.my.id/srv/diklat", {'tahun': new Date().getFullYear(), "event_status":"finished" },setPelatihanSelesai)
    }

    if(currPelatihan && (pelatihanMendatang || pelatihanSelesai)) {
      require('../assets/js/Dashboard.js')
    }

    // console.log(pelatihanMendatang)

    return (
      <Content>
        {currPelatihan && (pelatihanMendatang || pelatihanSelesai) ? (<div className="d-flex flex-wrap justify-content-around dashboard--wrapper">
          <section className='d-flex flex-column content-section' id='left_section'>
            <Clock />
            <InfoPelatihan 
              items={currPelatihan}
            />
            <DetilPelatihan 
              items={currPelatihan}
            />
            <JenisPelatihan
              items={currPelatihan}
            />
          </section>
          <section className='d-flex flex-column content-section' id='mid-section'>
            <Summary
              items={currPelatihan}
            />
            <Dokumentasi 
              items={currPelatihan}
            />
            <Client />
          </section>
          <section className='d-flex flex-column content-section' id='right-section'>
            <PelatihanMendatang
              items={pelatihanMendatang.length > 0 ? pelatihanMendatang : pelatihanSelesai}
              jenis={pelatihanMendatang.length > 0 ? 'Pelatihan Mendatang' : 'Pelatihan Selesai'}
            />
            <Kontak />
          </section>
        </div>) : (
          <div className='d-flex justify-content-center align-items-center'>
            Loading
          </div>
        )}
        
        {/* {items.data.map(item => (
          <p>{item.nama}</p>
        ))} */}
      </Content>   
    )

}