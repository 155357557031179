import React, {Suspense} from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import Dashboard from '../components/Dashboard'
import DetilPeserta from '../components/DetilPeserta'
import {HeaderWrapper} from '../_metronic/layout/components/header/HeaderWrapper'
import { Footer } from '../_metronic/layout/components/Footer'

// import AuthInit from './modules/auth/redux/AuthInit'
// import {Routes} from './routing/Routes'

type Props = {
  basename: string,
  
}

const App: React.FC<Props> = ({basename}) => {

  const [isDarkMode, setDarkMode] = React.useState<any>(false)
    const handleDarkMode = () => setDarkMode(!isDarkMode)

    // const navigate = useNavigate();

    React.useEffect(() => {
      setDarkMode(window.sessionStorage.getItem("isDarkMode") ? JSON.parse(window.sessionStorage.getItem("isDarkMode") || "") : "");
    }, []);

    React.useEffect(() => {
      window.sessionStorage.setItem("isDarkMode", isDarkMode);
    }, [isDarkMode]);
    
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            {/* <AuthInit>
              <Routes />
            </AuthInit> */}

            <div className='page d-flex flex-row flex-column-fluid'>
              <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper' style={{'backgroundColor': isDarkMode ? '#0E2B55' : ''}}>
                <HeaderWrapper />
                <div id='switch_mode'>
                  <input type="checkbox" className="checkbox" id="checkbox" onClick={handleDarkMode} checked={isDarkMode} readOnly/>
                  <label htmlFor="checkbox" className={isDarkMode ? "label bg-dark" : "label"}>
                    <i className='far fa-sun'></i>
                    <i className="far fa-moon"></i>
                    <div className='ball' />
                  </label>
                </div>

                <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                  {/* <Toolbar /> */}
                  <div className='post d-flex flex-column-fluid' id='kt_post'>

                    <Switch>
                      <Route path="/" exact>
                        <Dashboard />
                      </Route>

                      <Route path="/detil_peserta">
                        <DetilPeserta />
                      </Route>
                    </Switch>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
